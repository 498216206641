<template>
  <header class="header">
    <div class="wrapper">
      <div class="flex">
        <div class="flex-left">
          <router-link to="/" class="logo">
            <img src="./../images/logo.svg" class="img"/>
          </router-link>
          <ul class="nav">
            <li class="nav__item" v-for="item in typeOptions" :key="item.id">
              <div :class="['desc', {'active' : $parent.appActiveType ==  item.title}]" @click="$emit('chooseType', item)">
                {{$t(item.title)}}
              </div>
            </li>
            <li class="nav__item">
              <router-link to="/escrow" class="desc">
                {{$t('Escrow service')}}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="flex-right">
          <div class="header-profile" v-if="$parent.isAuth">
            <div class="select-container cart" v-if="curr && (currOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                      @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{$t(item)}}</option>
              </select>
            </div>
            <div class="balance-block">
              <div class="desc">{{$t('My balance')}}:</div>
              <div class="title small">{{ $parent.userBalance }} {{ $parent.currency }}</div>
            </div>
            <router-link class="profile-desc" to="/profile/main">
              <div class="desc name">{{ $parent.userName }}</div>
              <div class="icon button">
                <img class="img" src="./../images/user.svg"/>
              </div>
            </router-link>
            <!-- <router-link to="/cart" class="button cart blue">
              <img class="img" src="./../images/cart.svg"/>
              <div v-if="$parent.cartContents && $parent.cartContents.length" class="indicator">{{ $parent.cartContents.length }}</div>
            </router-link> -->
          </div>
          <div v-else class="buttons">
            <div class="select-container cart" v-if="curr && (currOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                      @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{$t(item)}}</option>
              </select>
            </div>
            <div class="button" @click="$emit('openSignInModal')">
              <span>{{$t('Login')}}</span>
            </div>
            <div class="button blue" @click="$emit('openSignUpModal')">
              <span>{{$t('Sign Up')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { RouterLink } from 'vue-router';


export default {
    name: 'Header',
    props: ['currencyCode', 'currencyValue'],
    data: function () {
        return {
            curr: '',
            typeOptions: []
        };
    },
    computed: {
        currOptions() {
            return this.$parent.currencyOptions.map(({ code }) => code);
        }
    },
    watch: {
        currencyCode: function (newValue) {
            this.curr = newValue;
        }
    },
    mounted() {
        this.curr = this.currencyCode;
        this.$http.get(process.env.VUE_APP_API + 'categories')
            .then((res) => {
            this.typeOptions = res.data.payload.filter(item => item.parent_id === null);
        })
            .catch(() => {
        });
    },
    methods: {
        openSignInModal() {
            this.$emit('openSignInModal');
        },
        openSignUpModal() {
            this.$emit('openSignUpModal');
        },
        scrollToSection(section) {
            this.$emit('scrollToSection', section);
        },
        selectCurr() {
            this.changeCurrency(this.curr);
            localStorage.setItem("currency", this.curr);
        },
        changeCurrency(item) {
            this.$emit('changeCurrency', item);
        },
    },
    components: { RouterLink }
}
</script>
